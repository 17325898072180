import React from "react"
import ApiUrl from "../ApiUrl"

function MainPartner(props) {
    const imgUrl = (props.data.cover) ? ApiUrl + props.data.cover : "";
    return (
        <a target="_blank" href={props.data.url} className="main-partners-entry"><img src={imgUrl} alt=""/></a>
    )
}

export default MainPartner