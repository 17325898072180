import React from "react";

function MainSocNet() {
    return (
        <div id="main-social-networks">
            <a target='_blank' href='https://vk.com/kipelov_official' className="main-social-networks-vk"></a>
            <a target='_blank' href='https://www.instagram.com/kipelovofficial/'
               className="main-social-networks-instagram"></a>
            <a target='_blank' href='http://www.facebook.com/KipelovOfficial'
               className="main-social-networks-facebook"></a>
            <a target='_blank' href='http://twitter.com/KipelovOfficial' className="main-social-networks-twitter"></a>
            <a target='_blank' href='http://www.youtube.com/Kipelov' className="main-social-networks-youtube"></a>
            <a target='_blank' href='https://ok.ru/kipelovofficial' className="main-social-networks-ok"></a>
        </div>
    );
}

export default MainSocNet;