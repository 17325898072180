import React from "react"
import ApiUrl from "../ApiUrl";
import MainPartner from "./MainPartner";

class MainPartners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data: [], loading: true}
    }

    componentDidMount() {
        fetch(ApiUrl + "/api/v1/partners?sort=order&expand=cover")
            .then(response => response.json())
            .then(data => this.setState(
                {data: data, loading: false}
            ))

    }

    render() {
        const partnerComponents = this.state.data.map(partnersItem => <MainPartner key={partnersItem.id}
                                                                                   data={partnersItem}/>);
        return (
            <div id="main-partners" className="main-block">
                <div className="main-block-wrapper">
                    <div className="main-block-content">
                        <div id="main-partners-list">
                            {partnerComponents}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MainPartners