import React from "react";
import {Link} from "react-router-dom";
import Moment from "react-moment";
import 'moment/locale/ru';

function MainConcert(props) {
    return (
        <div className="concert-list-item">
            <div className="concert-list-item-date text-date">
                <Moment format="D MMMM YYYY">
                    {props.data.datetime}
                </Moment>
            </div>
            <div className="concert-list-item-city">
                <Link to="/concerts">
                    {props.data.name}
                </Link>
            </div>
            <div className="concert-list-item-place">
                {props.data.place_short}
            </div>
        </div>
    )
}

export default MainConcert