import React from "react"

function MainVideoGalleryEntry(props) {
    console.log(props);
    return (
        <div className="main-video-gallery-item">
            <a href={"https://www.youtube.com/watch?v=" + props.data.id.videoId} target="_blank">
                <img src={props.data.snippet.thumbnails.medium.url} alt=""/>
            </a>
        </div>
    )
}

export default MainVideoGalleryEntry