import React from "react"
import Moment from "react-moment";
import ApiUrl from "../ApiUrl";
import {Link} from "react-router-dom";

function MainNewsFull(props) {
    const img = (props.data.cover) ? ApiUrl + props.data.cover : '/img/pic_sample_1.png';
    return (
        <div id="main-news-full">
            <div id="main-news-full-image">
                <img src={img} alt=""/>
            </div>
            <div id="main-news-full-title">
                <span className="text-date">
                    <Moment format="D MMMM">
                    {props.data.date_start}
                </Moment>
                </span> <Link to={'/news/' + props.data.id}>{props.data.title}</Link>
            </div>
            {props.data.text.replace(/<br\s*\/?>/gi, ' ').replace(/<[^>]+>/g, '').replace(/<[^>]+>/g, '')}
        </div>
    )
}

export default MainNewsFull