import React from "react"
import MainGalleryEntry from "./MainGalleryEntry";
import MainVideoGalleryEntry from "./MainVideoGalleryEntry";
import ModalGalleryPhotoAlbum from "../modals/ModalGalleryPhotoAlbum";
import ModalRoute from "react-router-modal/lib/modal_route";
import ApiUrl from "../ApiUrl";
import {Link} from "react-router-dom";

class MainGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {albumsData: [], albumsLoading: true, videosData: [], videosLoading: true}
    }

    componentDidMount() {
        // albums
        const albumsUrl = ApiUrl + "/api/v1/photoalbums/get-random?expand=pictures";
        fetch(albumsUrl)
            .then(response => response.json())
            .then(data => this.setState(
                {albumsData: data, albumsLoading: false}
            ));

        // videos
        const videosUrl = ApiUrl + "/api/v1/videos";
        fetch(videosUrl)
            .then(response => response.json())
            .then(data => this.setState(
                {videosData: data, videosLoading: false}
            ));
    }

    render() {
        let galleryComponents = '';
        let videoGalleryComponents = '';
        // albums
        if (!this.state.albumsLoading) {
            galleryComponents = this.state.albumsData.pictures.map(galleryItem =>
                <MainGalleryEntry
                    key={galleryItem.id}
                    albumId={this.state.albumsData.id}
                    data={galleryItem}/>);
        }
        // videos
        if (!this.state.videosLoading) {
            videoGalleryComponents = '';
            if (this.state.videosData) {
                videoGalleryComponents = this.state.videosData.map(videoItem =>
                    <MainVideoGalleryEntry
                        key={videoItem.id.videoId}
                        data={videoItem}/>);
            }
        }
        let headerLink = (this.state.albumsLoading) ? '' :
            <Link className="main-block-link" to={"/galleries/photos/" + this.state.albumsData.id}/>;
        return (
            <div id="main-gallery" className="main-block">
                <div className="main-block-wrapper">
                    {headerLink}
                    <div className="main-block-content">
                        <div id="main-gallery-photo-label">
                            {this.state.albumsData.name}
                        </div>
                        <div id="main-gallery-container">
                            {galleryComponents}
                        </div>
                        <div id="main-gallery-video-label"></div>
                        <div id="main-gallery-container">
                            {videoGalleryComponents}
                        </div>
                    </div>
                </div>
                <ModalRoute path='/galleries/photos/:galleryId' parentPath='/'
                            component={ModalGalleryPhotoAlbum} className={'modal-long-content'}></ModalRoute>
            </div>
        )
    }
}

export default MainGallery