import React from "react"
import {Link} from "react-router-dom";
import ApiUrl from "../ApiUrl";
import moment from "moment";
import Moment from "react-moment";

class ModalNewsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data: [], loading: true};
    }

    componentDidMount() {
        fetch(ApiUrl + "/api/v1/news/?fields=id,title,date_start&sort=-date_start")
            .then(response => response.json())
            .then(data => this.setState(
                {data: data, loading: false}
            ))
    }

    selectNews(newsId) {
        this.props.selectNews(newsId);
    }

    render() {
        let newsList = [];
        if (!this.state.loading) {
            let year = 0;
            // news array walk
            this.state.data.forEach((newsEntry) => {
                let currentYear = moment(newsEntry.date_start).year();
                if (currentYear != year) {
                    newsList.push(<div key={currentYear} className='popup-news-list-year'>{currentYear}</div>)
                    year = currentYear;
                }
                newsList.push(<div key={newsEntry.id} className="popup-news-list-entry">
                    <div className="popup-news-list-entry-date text-date">
                        <Moment format="D MMMM">
                            {newsEntry.date_start}
                        </Moment>
                    </div>
                    <div className="popup-news-list-entry-title">
                        <Link to={"/news/" + newsEntry.id} onClick={() => {
                            this.selectNews(newsEntry.id)
                        }}>
                            {newsEntry.title}
                        </Link>
                    </div>
                </div>)
            });
            return newsList;
        } else {
            return <div></div>;
        }
    }
}

export default ModalNewsList;