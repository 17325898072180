import React from "react";
import {BrowserRouter} from 'react-router-dom';
import MainConcerts from "./components/concerts/MainConcerts";
import MainNews from "./components/news/MainNews";
import MainGallery from "./components/gallery/MainGallery";
import MainPartners from "./components/partners/MainPartners";
import MainMusicians from "./components/musicians/MainMusicians";
import MainAlbums from "./components/albums/MainAlbums";
import MainShop from "./components/shop/MainShop";
import MainContacts from "./components/contacts/MainContacts";
import SimpleReactLightbox from "simple-react-lightbox";
import 'react-router-modal/css/react-router-modal.css';
import ModalContainer from "react-router-modal/lib/modal_container";
import MainSocNet from "./components/socnet/Main";

// import Footer from "./components/footer/Footer";

// router-modal

class App extends React.Component {
    constructor() {
        super()
    }

    render() {
        document.title = 'Группа Кипелов - Главная';
        return (
            <BrowserRouter>
                <div>
                    <SimpleReactLightbox transitionSpeed={0} showThumbnails={false}>
                        <div id="main-cover" className="main-block">
                            <img src="/img/cover.jpg" alt=""/>
                            <MainSocNet/>
                        </div>
                        <MainConcerts/>
                        <MainNews/>
                        <MainGallery/>
                        <MainMusicians/>
                        <MainAlbums/>
                        <MainContacts/>
                        <MainPartners/>
                        <MainShop/>
                        <ModalContainer/>
                    </SimpleReactLightbox>
                </div>
            </BrowserRouter>
        )
    }
}

export default App
