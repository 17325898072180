import React from "react"
import {Link} from "react-router-dom";
import MainMusiciansEntry from "./MainMusiciansEntry";
import ModalMusicians from "../modals/ModalMusicians";
import ModalRoute from "react-router-modal/lib/modal_route";
import ApiUrl from "../ApiUrl";

class MainMusicians extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data: [], loading: true}
    }

    createMarkup(htmlCode) {
        return {__html: htmlCode};
    }

    componentDidMount() {
        fetch(ApiUrl + "/api/v1/texts/1")
            .then(response => response.json())
            .then(data => this.setState(
                {data: data, loading: false}
            ))
    }

    render() {
        const textBlock = (this.state.loading) ? '' : this.state.data.text;
        return (
            <div id="main-musicians" className="main-block">
                <div className="main-block-wrapper">
                    <Link className="main-block-link" to={"/musicians/"}/>
                    <div className="main-block-content">
                        <div id="main-musicians-container">
                            <MainMusiciansEntry data={{key: 4}}/>
                            <MainMusiciansEntry data={{key: 2}}/>
                            <MainMusiciansEntry data={{key: 1}}/>
                            <MainMusiciansEntry data={{key: 3}}/>
                            <MainMusiciansEntry data={{key: 5}}/>
                        </div>
                        <div id="main-musicians-history">
                            <div>
                                <div dangerouslySetInnerHTML={this.createMarkup(this.state.data.text)}></div>
                                <div id="main-musicians-read-more">
                                    <Link to='/musicians'>Подробнее</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalRoute path='/musicians' parentPath='/'
                            component={ModalMusicians} className={'modal-long-content'}></ModalRoute>
                <ModalRoute path='/musicians/:musicianId' parentPath='/'
                            component={ModalMusicians} className={'modal-long-content'}></ModalRoute>
            </div>
        )
    }
}

export default MainMusicians