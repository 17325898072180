import React from "react"
import moment from "moment";
import {Link} from 'react-router-dom';
import ApiUrl from "../ApiUrl";
import ModalGalleryMenu from "../gallery/ModalGalleryMenu";
import ModalGalleryPictures from "../gallery/ModalGalleryPictures";
import photoAlbumCategories from "../gallery/galleryTypes";


class ModalGalleryPhotoAlbum extends React.Component {

    createMarkup(htmlCode) {
        return {__html: htmlCode};
    }

    constructor(props) {
        super(props);
        this.state = {data: [], loading: true};
        this.state.selectedPhotoAlbumId = this.props.match.params.galleryId;
        this.selectPhotoAlbum = this.selectPhotoAlbum.bind(this);
    }

    componentDidMount() {
        if (this.state.selectedPhotoAlbumId) {
            let url = ApiUrl + "/api/v1/photoalbums/" + this.state.selectedPhotoAlbumId + "?expand=pictures";
            fetch(url)
                .then(response => response.json())
                .then(data => this.setState(
                    {data: data, loading: false, selectedCategoryId: data.category_id}
                ))
        }
    }

    selectPhotoAlbum(albumId) {
        let url = ApiUrl + "/api/v1/photoalbums/" + albumId + "?expand=pictures";
        this.setState({loading: true, selectedPhotoAlbumId: albumId});
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState(
                {data: data, loading: false, selectedPhotoAlbumId: albumId}
            ))
    }

    render() {

        document.title = 'Группа Кипелов - Галерея: ' + this.state.data.name;
        let galleryPicturesComponent = '';
        let galleryHeader;
        let modalGalleryMenu;
        if (!this.state.loading) {
            if (this.state.data.category_id == 1) {
                galleryHeader = <span className="popup-gallery-photoalbum-name">
                    {this.state.data.name}
                </span>;
            } else {
                const selectedYear = (this.state.data.date) ?
                    <span className="popup-gallery-photoalbum-year">{moment(this.state.data.date).year()}</span> : '';
                galleryHeader = <React.Fragment>
                    <span
                        className="popup-gallery-photoalbum-category">{photoAlbumCategories[this.state.data.category_id - 1].title}</span> {selectedYear}
                    <span className="popup-gallery-photoalbum-name">{this.state.data.name}</span>
                </React.Fragment>;
            }
            modalGalleryMenu = <ModalGalleryMenu selectPhotoAlbum={this.selectPhotoAlbum}
                                                 selectedPhotoAlbumId={this.state.selectedPhotoAlbumId}
                                                 selectedCategoryId={this.state.data.category_id}
                                                 selectedYear={(this.state.data.date) ? moment(this.state.data.date).year() : ''}
            />;
            galleryPicturesComponent =
                <ModalGalleryPictures photoAlbumId={this.state.data.id} data={this.state.data.pictures}/>
        }
        return (
            <div className="popup-container popup-container-wide">
                <div className="popup-gallery">
                    <div className="popup-wrapper">
                        <div className="popup-controls">
                            <Link className="popup-close" to={this.props.parentPath}></Link>
                        </div>
                        <div className="popup-content">
                            <div>
                                {modalGalleryMenu}
                            </div>
                            <div>
                                <div className="popup-gallery-header">
                                    {galleryHeader}
                                </div>
                                <div className="popup-gallery-photoalbum-description"
                                     dangerouslySetInnerHTML={this.createMarkup(this.state.data.description)}>
                                </div>
                                {galleryPicturesComponent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
            ;
    }
}

export default ModalGalleryPhotoAlbum;