import React from "react"
import {Link} from "react-router-dom"
import MainConcert from "./MainConcert";
import ApiUrl from "../ApiUrl";
import ModalRoute from "react-router-modal/lib/modal_route";
import ModalConcerts from "../modals/ModalConcerts";

class MainConcerts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data: []}
    }

    componentDidMount() {
        fetch(ApiUrl + "/api/v1/concerts?sort=datetime")
            .then(response => response.json())
            .then(concerts => this.setState(
                {data: concerts}
            ))

    }

    render() {
        const concertComponents = this.state.data.map(concert => <MainConcert key={concert.id} data={concert}/>)
        return (
            <div id="main-concerts" className="main-block">
                <div className="main-block-wrapper">
                    <Link className="main-block-link" to="/concerts"/>
                    <div className="main-block-content">
                        <div id="main-concerts-header">
                            <div className="concerts-header">
                                <span>Кипелов</span> в туре
                            </div>
                        </div>
                        <div id="concert-list">
                            {concertComponents}
                        </div>
                    </div>
                </div>
                <ModalRoute path='/concerts' parentPath='/' component={ModalConcerts}></ModalRoute>
            </div>
        )
    }
}

export default MainConcerts