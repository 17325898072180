import React from "react"
import Moment from "react-moment";
import {Link} from "react-router-dom";

function NewsEntry(props) {
    return (
        <div className="main-news-entry">
            <div className="main-news-entry-date text-date">
                <Moment format="D MMMM YYYY">
                    {props.data.date_start}
                </Moment>
            </div>
            <div className="main-news-entry-title">
                <Link to={'/news/' + props.data.id}>{props.data.title}</Link>
            </div>
        </div>
    )
}

export default NewsEntry