import React from "react"
import MainAlbumsEntry from "./MainAlbumsEntry";
import ModalRoute from "react-router-modal/lib/modal_route";
import ModalAlbums from "../modals/ModalAlbums";
import ModalAlbum from "../modals/ModalAlbum";
import ApiUrl from "../ApiUrl";
import {Link} from "react-router-dom";

class MainAlbums extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data: [], loading: true}
    }

    componentDidMount() {
        fetch(ApiUrl + "/api/v1/albums?type_id=1&sort=year&expand=cover")
            .then(response => response.json())
            .then(data => this.setState(
                {data: data, loading: false}
            ))

    }

    render() {
        let albumComponents = this.state.data.map(albumsItem => <MainAlbumsEntry key={albumsItem.id}
                                                                                 data={albumsItem}/>);
        return (
            <div id="main-albums" className="main-block">
                <div className="main-block-wrapper">
                    <Link className="main-block-link" to={"/albums/"}/>
                    <div className="main-block-content">
                        <div id="main-albums-list">
                            {albumComponents}
                        </div>
                    </div>
                </div>
                <ModalRoute path='/albums/' parentPath='/'
                            component={ModalAlbums} className={'modal-long-content'}></ModalRoute>
                <ModalRoute path='/albums/:albumId' parentPath='/'
                            component={ModalAlbum} className={'modal-long-content'}></ModalRoute>
            </div>
        )
    }
}

export default MainAlbums