import React from "react"
import NewsEntry from "./NewsEntry";
import MainNewsFull from "./MainNewsFull";
import ApiUrl from "../ApiUrl";
import ModalNews from "../modals/ModalNews";
import ModalRoute from "react-router-modal/lib/modal_route";
import {Link} from "react-router-dom";

class MainNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data: [], loading: true}
    }

    componentDidMount() {
        fetch(ApiUrl + "/api/v1/news?expand=cover&sort=-date_start")
            .then(response => response.json())
            .then(data => this.setState(
                {data: data, loading: false}
            ))

    }

    render() {
        const newsList = this.state.data;
        const firstNews = this.state.data[0];
        newsList.shift();
        let newsComponents = newsList.map(newsItem => <NewsEntry key={newsItem.id} data={newsItem}/>);
        let firstNewsComponent = (this.state.loading) ? '' : <MainNewsFull key={firstNews.id} data={firstNews}/>;
        let headerLink = (this.state.loading) ? '' :
            <Link key="headerLink" className="main-block-link" to={"/news/" + firstNews.id}/>;
        return (
            <div id="main-news" className="main-block">
                <div className="main-block-wrapper">
                    {headerLink}
                    <div className="main-block-content">
                        {firstNewsComponent}
                        <div id="main-news-list">
                            {newsComponents}
                        </div>
                    </div>
                </div>
                <ModalRoute path='/news/:newsId' parentPath='/'
                            component={ModalNews} className={'modal-long-content'}></ModalRoute>
            </div>
        )
    }
}

export default MainNews