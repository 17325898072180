import React from "react"
import {Link} from "react-router-dom";
import moment from "moment";
import ModalGalleryMenuItem from "./ModalGalleryMenuItem";

class ModalGalleryMenuCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selectedCategoryId: 0, selectedYear: 0, selectedPhotoAlbumId: 0};
        this.selectPhotoAlbum = this.selectPhotoAlbum.bind(this);
        this.state.selectedYear = props.selectedYear;
    }

    selectCategory(categoryId) {
        this.props.selectCategory(categoryId);
    }

    selectYear(year) {
        this.setState({
                'selectedYear': year
            }
        )
    }

    selectPhotoAlbum(albumId) {
        this.props.selectPhotoAlbum(albumId);
    }

    render() {

        let selectedClassName = '';

        if (this.props.categoryId == 2) {
            // albums year by year
            let photoAlbumsListByYear = this.props.data.photoAlbums.sort(function (a, b) {
                return (moment(b.date) - moment(a.date))
            });

            let photoAlbumsByYear = [];
            let year = 0;
            let i = -1; // incrementing
            photoAlbumsListByYear.forEach(function (photoAlbum) {
                if (moment(photoAlbum.date).year() != year) {
                    year = moment(photoAlbum.date).year();
                    photoAlbumsByYear.push({
                        year: year,
                        photoAlbums: []
                    });
                    ++i;
                }
                // push data
                photoAlbumsByYear[i].photoAlbums.push(photoAlbum);
            });
            let photoAlbumsComponents = [];
            photoAlbumsByYear.forEach((photoAlbumByYear) => {
                if (this.state.selectedYear == photoAlbumByYear.year && this.props.selectedFlag) {
                    selectedClassName = 'selected';
                } else {
                    selectedClassName = '';
                }

                let photoAlbumsByYearComponents = photoAlbumByYear.photoAlbums.map(photoAlbumItem =>
                    <ModalGalleryMenuItem
                        key={photoAlbumItem.id}
                        id={photoAlbumItem.id}
                        name={photoAlbumItem.name}
                        selected={(photoAlbumItem.id == this.props.selectedPhotoAlbumId)}
                        selectPhotoAlbum={this.selectPhotoAlbum}
                    />);
                photoAlbumsComponents.push(
                    <dd key={photoAlbumByYear.year} className={selectedClassName}>
                        <dl>
                            <dt onClick={() => {
                                this.selectYear(photoAlbumByYear.year)
                            }}>{photoAlbumByYear.year}</dt>
                            {photoAlbumsByYearComponents}
                        </dl>
                    </dd>
                );
            });
            if (this.props.selectedFlag) {
                selectedClassName = 'popup-photoalbums-category selected';
            } else {
                selectedClassName = 'popup-photoalbums-category';
            }
            return (
                <dd className={selectedClassName}>
                    <dl>
                        <dt onClick={() => {
                            this.selectCategory(this.props.categoryId);
                        }}>{this.props.categoryTitle}</dt>
                        {photoAlbumsComponents}
                    </dl>
                </dd>
            )
        } else {
            let photoAlbumComponents = this.props.data.photoAlbums.map(photoAlbumItem => <ModalGalleryMenuItem
                key={photoAlbumItem.id}
                id={photoAlbumItem.id}
                name={photoAlbumItem.name}
                selectPhotoAlbum={this.selectPhotoAlbum}
                selected={(photoAlbumItem.id == this.props.selectedPhotoAlbumId)}
            />);
            if (photoAlbumComponents.length > 1) {
                // personal pictures, different
                if (this.props.selectedFlag) {
                    selectedClassName = 'popup-photoalbums-category selected';
                } else {
                    selectedClassName = 'popup-photoalbums-category';
                }
                return (
                    <dd className={selectedClassName}>
                        <dl className="popup-photoalbums-category-list">
                            <dt onClick={() => {
                                this.selectCategory(this.props.categoryId);
                            }}
                                className="popup-photoalbums-category-title">{this.props.categoryTitle}</dt>
                            {photoAlbumComponents}
                        </dl>
                    </dd>
                );
            } else {
                // history
                const className = (this.props.selectedPhotoAlbumId == 1) ? 'selected' : '';
                return (
                    <dd className={"popup-photoalbums-category"}>
                        <dl>
                            <dt className={className}>
                                <Link to={"/galleries/photos/" + this.props.data.photoAlbums[0].id}
                                      className={"popup-photoalbums-category-title"}
                                      onClick={() => {
                                          this.selectPhotoAlbum(this.props.categoryId);
                                          this.selectYear(0);
                                          this.selectCategory(1);
                                      }}>{this.props.categoryTitle}</Link>
                            </dt>
                        </dl>
                    </dd>
                );
            }
        }
    }
}

export default ModalGalleryMenuCategory;