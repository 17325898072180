import React from "react"
import {Link} from "react-router-dom";
import ApiUrl from "../ApiUrl";

function MainGalleryEntry(props) {
    return (<React.Fragment>
            <div className="main-gallery-item"><Link
                to={'/galleries/photos/' + props.albumId}><img
                src={ApiUrl + props.data.preview}
                alt=""/></Link>
            </div>
            <div className="main-gallery-separator"></div>
        </React.Fragment>
    )
}

export default MainGalleryEntry