import React from "react"
import {Link} from 'react-router-dom';
import ApiUrl from "../ApiUrl"

class ModalAlbum extends React.Component {
    createMarkup(htmlCode) {
        return {__html: htmlCode};
    }

    constructor(props) {
        super(props);
        this.state = {data: [], loading: true}
    }

    componentDidMount() {
        if (this.props.match.params.albumId) {
            fetch(ApiUrl + "/api/v1/albums/" + this.props.match.params.albumId + '?expand=description,songs,cover')
                .then(response => response.json())
                .then(data => this.setState(
                    {data: data, loading: false}
                ))
        }
    }

    render() {
        document.title = 'Группа Кипелов - Альбомы: ' + this.state.data.name;
        let songComponents = '';
        const img = (this.state.data.cover) ? ApiUrl + this.state.data.cover : '/img/big_album.jpg';
        if (!this.state.loading) {
            songComponents = this.state.data.songs.map(song => <li key={song.id}><span>{song.name}</span></li>);
        }
        return (
            <div className="popup-container">
                <div className="popup-album">
                    <div className="popup-wrapper">
                        <div className="popup-controls">
                            <Link className="popup-close" to={this.props.parentPath}></Link>
                        </div>
                        <div className="popup-content">
                            <div className="popup-album-cover">
                                <img src={img} alt=""/>
                            </div>
                            <div className="popup-album-songs">
                                <div className="popup-album-title">
                                    {this.state.data.name} <span>({this.state.data.year})</span>
                                </div>
                                Список песен:
                                <ol>
                                    {songComponents}
                                </ol>
                            </div>
                            <div className="popup-album-separator"></div>
                            <div className="popup-album-music"
                                 dangerouslySetInnerHTML={this.createMarkup(this.state.data.embed_player)}>
                            </div>
                            <div className="popup-album-text"
                                 dangerouslySetInnerHTML={this.createMarkup(this.state.data.description)}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalAlbum;