import React from "react"
import ModalGalleryEntry from "../gallery/ModalGalleryEntry";
import {SRLWrapper} from "simple-react-lightbox";

class ModalGalleryPictures extends React.Component {
    render() {
        const galleryComponents = this.props.data.map(galleryItem => <ModalGalleryEntry
            key={galleryItem.id}
            data={galleryItem}/>);
        return (
            <SRLWrapper photoAlbumId={this.props.photoAlbumId}>
                <div className="popup-gallery-photos">
                    {galleryComponents}
                </div>
            </SRLWrapper>
        )
    }
}

export default ModalGalleryPictures;