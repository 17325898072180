import React from "react"
import Moment from "react-moment";
import 'moment/locale/ru';

function ModalConcert(props) {
    document.title = 'Группа Кипелов - Концерты';
    return (
        <div className="concert-list-item">
            <div className="concert-list-item-date text-date">
                <Moment format="D MMMM YYYY">
                    {props.data.datetime}
                </Moment>
            </div>
            <div className="concert-list-item-city">
                {props.data.name}
            </div>
            <div className="concert-list-item-place">
                {props.data.place}
            </div>
            <div className="concert-list-item-ticket-link">
                <a target={'_blank'} href={props.data.ticket_link}><span>Билеты</span></a>
            </div>
        </div>

    )
}

export default ModalConcert