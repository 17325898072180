import React from "react"
import ApiUrl from "../ApiUrl";
import ModalGalleryMenuCategory from "./ModalGalleryMenuCategory";
import photoAlbumCategories from "./galleryTypes";

class ModalGalleryMenu extends React.Component {
    photoAlbumsSorted;

    constructor(props) {
        super(props);
        this.state = {data: [], loading: true, selectedYear: 0, selectedCategoryId: 0, selectedPhotoAlbumId: 0};
        this.state.selectedYear = this.props.selectedYear;
        this.state.selectedCategoryId = this.props.selectedCategoryId;
        this.state.selectedPhotoAlbumId = this.props.selectedPhotoAlbumId;
        this.selectCategory = this.selectCategory.bind(this);
        this.selectPhotoAlbum = this.selectPhotoAlbum.bind(this);
    }

    componentDidMount() {
        const url = ApiUrl + "/api/v1/photoalbums/all";
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState(
                {data: data, loading: false}
            ));
    }

    selectCategory(categoryId) {
        this.setState({
            selectedCategoryId: categoryId,
            selectedYear: 0
        })
    }

    selectPhotoAlbum(albumId) {
        this.props.selectPhotoAlbum(albumId);
        /*this.setState({
            'selectedPhotoAlbumId': albumId,
        })*/
    }

    render() {

        let photoAlbumsLinksComponent;
        if (!this.state.loading) {
            const photoAlbumsArray = this.state.data;
            this.photoAlbumsSorted = [];
            photoAlbumCategories.forEach((photoAlbumCategory) => {
                this.photoAlbumsSorted.push({
                    id: photoAlbumCategory.id,
                    title: photoAlbumCategory.title,
                    photoAlbums: photoAlbumsArray.filter(function (photoAlbum) {
                            return (photoAlbum.category_id == photoAlbumCategory.id);
                        }
                    )
                })
            });
            photoAlbumsLinksComponent = this.photoAlbumsSorted.map(categoryItem =>
                <ModalGalleryMenuCategory
                    key={categoryItem.id}
                    data={categoryItem}
                    categoryTitle={categoryItem.title}
                    categoryId={categoryItem.id}
                    selectCategory={this.selectCategory}
                    selectPhotoAlbum={this.selectPhotoAlbum}
                    selectedFlag={(categoryItem.id == this.state.selectedCategoryId)}
                    selectedYear={this.state.selectedYear}
                    selectedPhotoAlbumId={this.props.selectedPhotoAlbumId}
                />);
        }
        return (
            <dl id="popup-menu-container">
                {photoAlbumsLinksComponent}
            </dl>
        );
    }
}

export default ModalGalleryMenu;