import React from "react"
import ApiUrl from "../ApiUrl";

class MainShop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data: [], loading: true}
    }

    componentDidMount() {
        fetch(ApiUrl + "/api/v1/texts/4")
            .then(response => response.json())
            .then(data => this.setState(
                {data: data, loading: false}
            ))

    }

    createMarkup(htmlCode) {
        return {__html: htmlCode};
    }

    render() {
        return (
            <div id="main-shop" className="main-block">
                <div className="main-block-wrapper">
                    <div className="main-block-content"
                         dangerouslySetInnerHTML={this.createMarkup(this.state.data.text)}></div>
                </div>
            </div>
        );
    }
}

export default MainShop;