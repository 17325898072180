import React from "react"
import {Link} from 'react-router-dom';

import ApiUrl from "../ApiUrl";
import ModalAlbumsList from "../albums/ModalAlbumsList";


class ModalAlbums extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data: [], loading: true}
    }

    componentDidMount() {
        fetch(ApiUrl + "/api/v1/albums?expand=cover")
            .then(response => response.json())
            .then(data => this.setState(
                {data: data, loading: false}
            ))
    }

    render() {
        document.title = 'Группа Кипелов - Альбомы';
        let albumComponents;
        let albumTypes = [
            {id: 1, title: 'Альбомы'},
            {id: 2, title: 'Синглы'},
            {id: 3, title: 'Концертные записи'},
        ];
        let albumsSorted;
        if (!this.state.loading) {
            let albumsArray = this.state.data;
            albumsSorted = [];
            albumTypes.forEach(function (albumType) {
                albumsSorted.push({
                    id: albumType.id,
                    categoryName: albumType.title,
                    albums: albumsArray.filter(function (album) {
                        return (album.type_id == albumType.id);
                    })
                })
            });
            albumComponents = albumsSorted.map(categoryItem =>
                <ModalAlbumsList
                    key={categoryItem.id}
                    data={categoryItem}/>);
        }
        return (
            <div className="popup-container popup-container-wide">
                <div className="popup-albums">
                    <div className="popup-wrapper">
                        <div className="popup-controls">
                            <Link className="popup-close" to={this.props.parentPath}></Link>
                        </div>
                        <div className="popup-content">
                            {albumComponents}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalAlbums;