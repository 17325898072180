import React from "react"
import {Link} from "react-router-dom";

function MainMusiciansEntry(props) {
    return (
        <Link className="main-musicians-item" to={'/musicians/' + props.data.key}>
            <div className="main-musicians-instrument"></div>
            <div className="main-musicians-portrait"></div>
            <div className="main-musicians-name"></div>
        </Link>
    )
}

export default MainMusiciansEntry