import React from "react"
import {Link} from "react-router-dom";

class ModalGalleryMenuItem extends React.Component {
    constructor(props) {
        super(props);
        this.selectPhotoAlbum = this.selectPhotoAlbum.bind(this);
    }

    selectPhotoAlbum(albumId) {
        this.props.selectPhotoAlbum(albumId);
    }

    render() {
        const className = this.props.selected ? "main-gallery-category-item selected" : "main-gallery-category-item";

        return (
            <dd className={className}>
                <Link to={'/galleries/photos/' + this.props.id} onClick={() => {
                    this.selectPhotoAlbum(this.props.id)
                }}>
                    {this.props.name}
                </Link>
            </dd>
        )
    }
}

export default ModalGalleryMenuItem;