import React from "react"
import ModalAlbumsEntry from "./ModalAlbumsEntry"

function ModalAlbumsList(props) {
    const albumComponents = props.data.albums.map(albumItem =>
        <ModalAlbumsEntry
            key={albumItem.id}
            data={albumItem}/>);
    return (
        <div className="popup-albums-category">
            <div className="popup-albums-category-title">{props.data.categoryName}</div>
            <div className="popup-albums-category-list">
                {albumComponents}
            </div>
        </div>
    )
}

export default ModalAlbumsList