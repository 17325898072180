import React from "react"
import {Link} from "react-router-dom"
import ApiUrl from "../ApiUrl";

function MainAlbumsEntry(props) {
    const img = (props.data.cover) ? ApiUrl + props.data.cover : '/img/big_album.jpg';
    return (
        <Link to={/albums/ + props.data.id} className="main-albums-entry">
            <div className="main-albums-entry-cover">
                <img src={img} alt=""/>
                <img src={img} alt=""/>
            </div>
            <div className="main-albums-entry-text">
                <div className="main-albums-title">
                    {props.data.name}
                </div>
                <div className="main-albums-class">
                    {props.data.class}
                </div>
                <div className="main-albums-year">
                    {props.data.year}
                </div>
            </div>
        </Link>
    )
}

export default MainAlbumsEntry