import React from "react"
import {Link} from 'react-router-dom';
import ApiUrl from "../ApiUrl";
import {SRLWrapper} from "simple-react-lightbox";

class ModalMusicians extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data: [], loading: true}
    }

    createMarkup(htmlCode) {
        return {__html: htmlCode};
    }

    componentDidMount() {
        if (this.props.match.params.musicianId) {
            fetch(ApiUrl + "/api/v1/musicians/" + this.props.match.params.musicianId)
                .then(response => response.json())
                .then(data => this.setState(
                    {data: data, loading: false}
                ))
        } else {
            fetch(ApiUrl + "/api/v1/texts/2")
                .then(response => response.json())
                .then(data => this.setState(
                    {data: data, loading: false}
                ))
        }
    }

    render() {
        let musicianPictures = '';
        if (this.props.match.params.musicianId) {
            document.title = 'Группа Кипелов - Группа: ' + this.state.data.name;
            if (!this.state.loading) {
                if (this.state.data.pictures) {
                    musicianPictures = this.state.data.pictures.map(picture => <div>
                        <a href={ApiUrl + picture.original}
                           data-attribute="SRL"><img
                            src={ApiUrl + picture.preview} alt=""/></a>
                    </div>);
                }
            }
            return (
                <div className="popup-container">
                    <div className="popup-musicians">
                        <div className="popup-wrapper">
                            <div className="popup-controls">
                                <Link className="popup-close" to={this.props.parentPath}></Link>
                            </div>
                            <div className="popup-content">
                                <div className="popup-musicians-picture">
                                    <img src={`/img/musicians/photo/${this.state.data.id}.jpg`} alt=""/>
                                </div>
                                <div className="popup-musicians-content">
                                    <div className="popup-musicians-text"
                                         dangerouslySetInnerHTML={this.createMarkup(this.state.data.description)}></div>
                                    <div className="popup-musicians-pictures">
                                        <SRLWrapper>
                                            {musicianPictures}
                                        </SRLWrapper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            document.title = 'Группа Кипелов - Группа';
            const textBlock = (this.state.loading) ? '' : this.state.data.text;
            return (
                <div className="popup-container">
                    <div className="popup-musicians">
                        <div className="popup-wrapper">
                            <div className="popup-controls">
                                <Link className="popup-close" to={this.props.parentPath}></Link>
                            </div>
                            <div className="popup-content">
                                <div className="popup-musicians-picture" id="popup-musicians-link-img">
                                    <img src={`/img/musicians/photo/0.jpg`} alt=""/>
                                    <div id="popup-musicians-link-map">
                                        <Link className="" to="/musicians/4"></Link>
                                        <Link className="" to="/musicians/2"></Link>
                                        <Link className="" to="/musicians/1"></Link>
                                        <Link className="" to="/musicians/3"></Link>
                                        <Link className="" to="/musicians/5"></Link>
                                    </div>
                                </div>

                                <div className="popup-musicians-text"
                                     dangerouslySetInnerHTML={this.createMarkup(this.state.data.text)}></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default ModalMusicians;