import React from "react"
import ModalNewsList from "../news/ModalNewsList"
import {Link} from 'react-router-dom';
import ApiUrl from "../ApiUrl";
import Moment from "react-moment";


class ModalNews extends React.Component {

    createMarkup(htmlCode) {
        return {__html: htmlCode};
    }

    constructor(props) {
        super(props);
        this.state = {data: []};
        this.selectNews = this.selectNews.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.newsId) {
            fetch(ApiUrl + "/api/v1/news/" + this.props.match.params.newsId)
                .then(response => response.json())
                .then(data => this.setState(
                    {data: data}
                ))
        }
    }

    selectNews(newsId) {
        fetch(ApiUrl + "/api/v1/news/" + newsId)
            .then(response => response.json())
            .then(data => this.setState(
                {data: data}
            ))
    }

    render() {
        if (this.props.match.params.newsId) {
            document.title = 'Группа Кипелов - Новости: ' + this.state.data.title;
            return (
                <div className="popup-container popup-container-wide">
                    <div className="popup-news">
                        <div className="popup-wrapper">
                            <div className="popup-controls">
                                <Link className="popup-close" to={this.props.parentPath}></Link>
                            </div>
                            <div className="popup-content">
                                <div className="popup-news-wrapper">
                                    <div className="popup-news-list">
                                        <ModalNewsList selectNews={this.selectNews}/>
                                    </div>
                                    <div className="popup-news-full">
                                        <div className="popup-news-title-container">
                                            <div>
                                                <div className="text-date">
                                                    <Moment format="D MMMM YYYY">{this.state.data.date_start}</Moment>
                                                </div>
                                                <div className="popup-news-title">
                                                    {this.state.data.title}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="popup-news-text"
                                             dangerouslySetInnerHTML={this.createMarkup(this.state.data.text)}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}

export default ModalNews;