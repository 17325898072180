import React from "react"
import ApiUrl from "../ApiUrl";
import ModalConcert from "./ModalConcert";
import {Link} from "react-router-dom";

class ModalConcerts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data: []}
    }

    componentDidMount() {
        fetch(ApiUrl + "/api/v1/concerts?sort=datetime")
            .then(response => response.json())
            .then(concerts => this.setState(
                {data: concerts}
            ))

    }

    render() {
        const concertComponents = this.state.data.map(concert => <ModalConcert key={concert.id} data={concert}/>)
        return (
            <div className="popup-container">
                <div className="popup-concerts">
                    <div className="popup-wrapper">
                        <div className="popup-controls">
                            <Link className="popup-close" to={this.props.parentPath}></Link>
                        </div>
                        <div className="popup-content">
                            <div className="concerts-header">
                                <span>Кипелов</span> в туре
                            </div>
                            <div className="concert-list">
                                {concertComponents}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalConcerts;