import React from "react"
import ApiUrl from "../ApiUrl";

function ModalGalleryEntry(props) {
    return (
        <div className="popup-gallery-entry"><a href={ApiUrl + props.data.original} data-attribute="SRL"><img
            src={ApiUrl + props.data.preview} alt=""/></a>
        </div>
    )
}

export default ModalGalleryEntry;